

export const planeData = {
  1: {
    name: 'primary',
    posX: -0.15,
    posY: 0.12,
    scale: 1.5,
    imgOffsetX: 1,
    imgOffsetY: 1,
    parallax: 0.0001,
  },
  2: {
    name: 'secondary',
    posX: -0.5,
    posY: -0.2,
    scale: 0.9,
    imgOffsetX: 0.5,
    imgOffsetY: 0.5,
    parallax: 0.0003,
  },
  3: {
    name: 'secondary',
    posX: 0.75,
    posY: 0.1,
    scale: 0.7,
    imgOffsetX: -0.04,
    imgOffsetY: 0.1,
    parallax: 0.0002,
  },
  4: {
    name: 'secondary',
    posX: 0.5,
    posY: -0.5,
    scale: 0.9,
    imgOffsetX: 0.5,
    imgOffsetY: -0.5,
    parallax: 0.00009,
  }
}
