import styled from "styled-components";


const Container = styled.section `
  overflow: auto;
  height: 100vh;
  width: 100%;
`;

const Content = styled.div `
  text-align: center;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  max-width: 800px;
  margin: auto;

  a {
    text-decoration: none;
  }
`;

const Headline = styled.h1 `
  color: white;
  font-family: "yum-font";
  font-size: 2em;
  letter-spacing: 0.3em;
`;

export {
  Container,
  Content,
  Headline
};
