import React, { Fragment } from 'react';
import SignUp from '../Modals/SignUp'

const Homepage = () => (
  <Fragment>
    <SignUp/>
    <h1>Hello, this is Homepage!</h1>
  </Fragment>
);

export default Homepage;
