import React, { Component } from 'react';
import { Container, Content, Headline } from './styles'

import { Link } from 'react-router-dom';
import moment from 'moment';
import {  getPostRoute } from '../../constants/routes';
import { postType } from '../../constants/propTypes';

class PostTeaser extends Component {
  render() {
    const { post } = this.props;
    const { id, updatedAt, title, path } = post;
    return (
      <Container>
        <Content>
          <Link to={getPostRoute(path)}>
            <Headline>{title}</Headline>
          </Link>
          <p className="text-muted small">{moment(updatedAt).fromNow()}</p>
        </Content>
      </Container>
    );
  }
}

PostTeaser.propTypes = {
  post: postType
};

export default PostTeaser;
