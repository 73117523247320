
export const normalizePost = ({
  sys: { id, updatedAt },
  fields: {
    title,
    content,
    path,
    primaryVideo,
    media
  },
}) => {
  return ({
    id,
    updatedAt,
    title,
    content,
    path,
    primaryVideo,
    media
  })
};

export const normalizePosts = posts => posts.map(normalizePost);
