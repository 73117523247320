import * as THREE from 'three';
import {
  groupArray,
  groupContainer,
  scene
} from '../../constants'

import {
  TweenMax,
  Power4
} from 'gsap';

import {
  eraseLines,
  expandLines
} from '../../utils/animate'

const planeGroup = new THREE.Group(); // [TODO] look back into this
planeGroup.name = 'planeGroup';


// contentful.js v4.x.x
const contentful = require('contentful')

const client = contentful.createClient({
  space: 't1je6me761ps',
  accessToken: 'e5adb71f81480469c6ad89cabe59b4ec7bad3937f0ef87cc71e9c462f3782cad'
})


const imgSlugArray = []; // storage of
const TextureObject = {}; // key value pairs for slug and texture

const loader = new THREE.TextureLoader();

const materialMaker = (slug) => new Promise((resolve) => {
  if (imgSlugArray.includes(slug) === false) { // check if slug is in array
    client.getAsset(slug)
      .then((asset) => {
        loader.load(`${asset.fields.file.url}`, (asset) => {
          resolve(TextureObject[slug] = asset); // assign unique geometry and key to object
        });
      })
    imgSlugArray.push(slug); // push slug to array in order to check against
  } else {
    setTimeout(() => { // [TODO] rewmove this shit in favor of something better
      resolve();
    }, 1000);

  }
});



const geoHeight = 0.5;
const geoWidth = 0.75;
const imgHeight = 1.2;
const imgWidth = 0.8 * 2;

const geometry = new THREE.PlaneBufferGeometry(geoHeight, geoWidth, .01);

const removeChildren = (parent) => {
  for (let i = parent.children.length - 1; i >= 0; i--) {
    if (parent.children[i].type === "Mesh") {

      eraseLines(parent.children[i], 0.8, 0)
      //parent.remove(parent.children[i])
    }
  }
}

const meshContainer = [];

// [TODO] check if plane has been created before to limit this
const generatePlanes = (x, yCoord, primaryImageID, maxPostDisplayed, index) => {
  let plane;

  const material = new THREE.MeshBasicMaterial({
    map: TextureObject[primaryImageID]
  });

  material.map.repeat.set(geoHeight / imgHeight, geoWidth / imgWidth);
//  material.map.offset.y = -0.2;
//  TweenMax.to(material.map.offset, 2, { x: 0.5, delay: 2})

  if (meshContainer.length < maxPostDisplayed) {
    plane = new THREE.Mesh(geometry, material);
    meshContainer.push(plane)
  } else {
    plane = meshContainer[index];
    plane.material = material;
  }

  plane.position.set(x, yCoord, 0);
  plane.scale.set(0.8, -0.8, 0.8);
//  plane.storedPositionY = yCoord;
  planeGroup.add(plane);

  expandLines(plane, 0.8, 0);
}


export default (hits, maxPostDisplayed) => {

  removeChildren(planeGroup);

  const columns = 3;
  let x;
  let y = 0.7;

  Object.keys(hits).forEach((index) => {
  //  x = (index - (hits.length - 1) / 2) * 0.6;

    x = (index % columns) ? (x += 0.6) : -0.6;
    y = (index % columns) ? y : y -= 0.8;

    const yCoord = (index % 2) ? y + 0.1 : y;
    const xCoord = x;

    const primaryImageID = hits[index].primaryImageID;

    materialMaker(primaryImageID).then((texture) => {
      generatePlanes(xCoord, yCoord, primaryImageID, maxPostDisplayed, index);
    });

  });

  if (!scene.getObjectByName('planeGroup')) { // check if this is already in scene
    scene.add(planeGroup)
  }

  console.log('scene', scene.children)
  console.log(imgSlugArray);
  console.log(TextureObject)
}
