import * as THREE from 'three';

import {
  TweenMax, Power4
} from 'gsap';


export const getScrollPercent = () => {
  const h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight';
  return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
}

let lastScrollTop = 0;
let scrollUp = false;


export const detectScrollDirection = () => {
  const st = window.pageYOffset || document.documentElement.scrollTop;
  if (st > lastScrollTop) {
    //   console.log('down');
    scrollUp = -1;
  } else {
    //   console.log('up');
    scrollUp = 1;
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  return scrollUp;
}

export const parallaxPlanes = (scene, direction) => {
  scene.traverse((child) => {
     if (child instanceof THREE.Mesh) {
      TweenMax.fromTo(child.position, 3, {
        y: `+=${getScrollPercent() * child.parallax * direction}`,
        ease: Power4.easeIn
      }, {
        y: child.storedPositionY,
        ease: Power4.easeOut
      })

    /*   TweenMax.to(child.scale, 0.2, {y: 1.5,
         ease: Power4.easeOut,
         onComplete: () => {
           TweenMax.to(child.scale, 0.3, {y: 1, ease: Power4.easeIn} )
         }
       }) */
    }
  });
}


export const scrollProgress = (progress) => {
  // Get all the Meters
  const meters = document.querySelectorAll('svg[data-value] .meter');
  meters.forEach((path) => {
    // Get the length of the path
    let length = path.getTotalLength();
    let value = parseInt(path.parentNode.getAttribute('data-value'));
    // Calculate the percentage of the total length
    let to = length * ((100 - Math.abs(progress)) / 100);
    path.getBoundingClientRect();
    // Set the Offset
    path.style.strokeDashoffset = Math.max(0, to);
  });
}
