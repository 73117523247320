import React, { Component, Fragment } from 'react'
import { Container, Headline, Wrapper, Next } from './styles'

import { Link } from 'react-router-dom';

import {
  TweenMax,
  Power4,
  Elastic
} from 'gsap';

class About extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {

   const node = this.myRef.current;

   if(node) {
     this.pageTransition(node);
   }

   // add class to body
   document.body.classList.add("post");
  }

  componentDidUpdate() {
    const node = this.myRef.current;
    this.pageTransition(node);
    }


pageTransition(node) {
  TweenMax.fromTo(node, 0.8, { height: '0vh', ease: Power4.easeOut},
    { height : '100vh', ease: Power4.easeIn })
}


 componentWillUnmount() {
  console.log('ENDS')
  document.body.classList.remove("post");
}

  render() {

    return (
        <Wrapper ref={this.myRef}>
          <Container>
            <Headline>Ummm...About Yummm</Headline>
              <div class="columns">
    <div class="column">
      <p>Our architecture seeks to create spaces with serenity.
Luis Barragán Morfin, an architect whose work we deeply respect, declared, "Serenity is the great and true antidote against anguish and fear, and today, more than ever, it is the architect's duty to make of it a permanent guest in the home, no matter how sumptuous or how humble."
We will continue to provide architectural spaces that embody beauty and pleasure in serene settings, touching the sentiments of those who use them.
We will, at the same time, work to create buildings that will endure both physically and aesthetically, informed by the techniques of sustainable design so as to be ecologically sound, and presenting appearances of timeless charm.</p>
    </div>
    <div class="column">
      <p>Our architecture seeks to create spaces with serenity.
Luis Barragán Morfin, an architect whose work we deeply respect, declared, "Serenity is the great and true antidote against anguish and fear, and today, more than ever, it is the architect's duty to make of it a permanent guest in the home, no matter how sumptuous or how humble."
We will continue to provide architectural spaces that embody beauty and pleasure in serene settings, touching the sentiments of those who use them.
We will, at the same time, work to create buildings that will endure both physically and aesthetically, informed by the techniques of sustainable design so as to be ecologically sound, and presenting appearances of timeless charm.</p>
    </div>
  </div>
          </Container>
        </Wrapper>
    )
  }
}


export default About;
