import { rootRoute, postsRoute, searchRoute, aboutRoute } from 'constants/routes';

export const navData = {
  Home: {
    to: postsRoute,
    activeClassName: 'is-active'
  },
  Search: {
    to: searchRoute,
    activeClassName: 'is-active'
  },
  About: {
    to: aboutRoute,
    activeClassName: 'is-active'
  },
}
