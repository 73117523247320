import styled from "styled-components";


const Navigation = styled.nav`
background-color: transparent;
position: relative;
z-index: 30;
position: absolute;
width: 100%;
display: inline-flex;
color:white;

  a {
    color: #4a4a4a;
    display: block;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    position: relative;
    text-decoration: none;
  }
`;
const NavChopSticksContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
`;

const Logo = styled.h1`
  color:white;
  font-size:1.3em;
  margin: 0;
`;

const FooterContainer = styled.div`
background: #524c4c;
}
`;

export { FooterContainer };
