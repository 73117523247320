import React from 'react';
import { ChopSticksSvg, ScrollProgressSvg } from './styles';


export const ChopSticks = () => {
  return (
      <ChopSticksSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.69 217.43">
            <g id="Layer_2" data-name="Layer 2">
              <g id="chop-sticks">
                <g className="left-stick">
                  <path d="M57.52,19.16A13,13,0,0,1,56.64,21a13.93,13.93,0,0,0-.83,2.51c-.5,1.91-.77,3.88-1.22,5.8Q43.24,78,32,126.71L14.14,203.77c-.11.45-.25.9-.42,1.49a3,3,0,0,1-1.08-3q13-62.43,26-124.87,5.85-28.17,11.68-56.33c.33-1.56-.38-3,.42-4.53a3.7,3.7,0,0,1,2.71-1.92,3.85,3.85,0,0,1,3,1,3.59,3.59,0,0,1,1.15,3.16C57.58,18.93,57.55,19,57.52,19.16Z" />
                </g>
                <g className="right-stick">
                  <path d="M92.53,5.23c-.43,1.57-1.65,3.08-2.36,4.44C89,11.88,88,14.13,87,16.41,85,21,83.19,25.62,81.26,30.2c-.56,1.33-1.13,2.66-1.72,4C75.18,43.86,71.14,53.68,67,63.45Q34.23,139.86,1.48,216.26c-.17.4-.35.78-.53,1.17-1.34-1.47-1.06-2.9-.39-4.55a6,6,0,0,1,.65-1.62L2,209.35l1.47-3.68,3-7.35,5.91-14.7L24.13,154.2Q30,139.5,35.94,124.79T47.75,95.38L59.56,66l11.8-29.41L83.17,7.13a21.4,21.4,0,0,0,1-3.75,4.36,4.36,0,0,1,2.5-3A4.41,4.41,0,0,1,92.5,3a3.92,3.92,0,0,1,.19,1.17A4.59,4.59,0,0,1,92.53,5.23Z" />
                </g>
              </g>
            </g>
          </ChopSticksSvg>
  );
};

export const ScrollProgress = () => {
  return (
    <ScrollProgressSvg>
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" data-value="0">
    <circle r="45" cx="50" cy="50" />
    <path className="meter" d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0" strokeLinecap="round" strokeLinejoin="round" strokeDashoffset="282.78302001953125" strokeDasharray="282.78302001953125" />
  </svg>
  </ScrollProgressSvg>
  );
};


export const SearchIcon = () => {
  return (
    <svg className="navSearch__submitIcon" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" enableBackground="new 0 0 18 19" width="25">
				<g>
					<g>
						<path fill="#FFFFFF" d="M9.8,13.5C9.1,13.8,8.3,14,7.5,14C3.9,14,1,11.1,1,7.5s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4
						  c0,1.3-0.4,2.6-1.2,3.6l0.7,0.7c0.9-1.2,1.4-2.8,1.4-4.3c0-4.1-3.3-7.4-7.4-7.4c-4.1,0-7.4,3.3-7.4,7.4s3.3,7.4,7.4,7.4
						  c1.1,0,2.1-0.2,3.1-0.7L9.8,13.5z"></path>
						<rect x="13" y="9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.3372 13.7923)" fill="#FFFFFF" width="0.9" height="11.1"></rect>
					</g>
				</g>
			</svg>
  );
};
