import { GET_POSTS, GET_POST } from './actionTypes';
import client from '../contentfulClient';
import { normalizePosts } from '../../utils/normalizer';

export const getPosts = () => {
  return {
    type: GET_POSTS,
    payload: client.getEntries({
      order:'-sys.createdAt',
    //  content_type: 'post',
      include: 2
    }).then(data => normalizePosts(data.items)),
  };
}


export const getPost = (path) => {
  return {
    type: GET_POST,
    payload: client.getEntries({
      order:'-sys.createdAt',
    //   'field.path': path,
      include: 2
    }).then(data => normalizePosts(data.items)),
  };
}
