import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PostTeaser from '../../components/PostTeaser';
import { getPosts } from '../../ducks/posts/actions';
import { postsType } from '../../constants/propTypes';

import { createAllPanels } from '../../webGL/helpers/createPlanes';


class Posts extends Component {
  componentDidMount() {
    this.props.getPosts();
    console.log(this.props, 'tt')
  }

  render() {
    const { posts } = this.props;
    return (
      <div className="container">
        { posts.map(post =>
          <PostTeaser key={post.path} post={post}/>
        )}
        { createAllPanels(posts) }
      </div>
    );
  }
}

Posts.propTypes = {
  posts: postsType,
  getPosts: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { posts } = state;
  return {
    posts,
  };
};

const mapDispatchToProps = {
  getPosts
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
