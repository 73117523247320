import * as THREE from 'three';
import {
  groupArray,
  groupContainer,
  scene,
  selectedPlaneGroup
} from '.../../constants'

import {
  planeData,
} from './data';

import {
  TweenMax,
  Power4
} from 'gsap';

const createVideo = (srcURL) => {
  const video = document.createElement('video');
  video.crossOrigin = "anonymous";
//  video.width = window.innerWidth;
//  video.height = window.innerHeight;
  video.loop = true;
  video.muted = true;
  video.src = srcURL;
  video.setAttribute('webkit-playsinline', 'webkit-playsinline');
  video.play();
  return video;
}

const createVideoTexture = (srcURL) => {
  const texture = new THREE.VideoTexture(createVideo(srcURL));
  texture.minFilter = THREE.LinearFilter;
  texture.magFilter = THREE.LinearFilter;
  texture.format = THREE.RGBFormat;
  texture.generateMipmaps = false;
  return texture;
}

const changeUVs = (geometry, unitx, unity, offsetx, offsety) => {
  var uvs = geometry.attributes.uv.array;
  for (var i = 0; i < uvs.length; i += 2) {
    uvs[i] = (uvs[i] + offsetx) * unitx;
    uvs[i + 1] = (uvs[i + 1] + offsety) * unity;
  }
}

const createPlane = (
  name, posX, posY, scale, imgOffsetX, imgOffsetY, parallax, material, group) => {
  const geometry = new THREE.PlaneBufferGeometry(0.8, 0.5, .01);

  changeUVs(geometry, 0.5, 0.5, imgOffsetX, imgOffsetY);

  const plane = new THREE.Mesh(geometry, material);
  plane.name = name;
  plane.position.set(posX, posY, 0);
  plane.scale.set(scale, scale, scale);
  plane.parallax = parallax;
  plane.storedPositionY = posY;
  group.add(plane);
}

const createPlanes = (srcURL, index, srcTitle) => {
  const group = new THREE.Group();
  group.scale.set(0.9, 0.9, 0.9); // [TODO] remove this shit
  group.position.y = -(index);
  group.name = srcTitle; // name of plane group matches its corrsponding post title

  const material = new THREE.MeshBasicMaterial({
    map: createVideoTexture(srcURL),
  });

  Object.keys(planeData).forEach((key) => {
    const {
      name,
      posX,
      posY,
      scale,
      imgOffsetX,
      imgOffsetY,
      parallax,
    } = planeData[key];
    createPlane(name, posX, posY, scale, imgOffsetX, imgOffsetY, parallax, material, group);
  });
  groupContainer.add(group);
  groupArray.push(group);
}


export const createAllPanels = (data) => {
  console.log('data', data)
  scene.remove(selectedPlaneGroup);
  const maxPost = 5;

  if (data.length) { // check if data has been loaded
    if (!Array.isArray(groupArray) || !groupArray.length) { // check if this array is empty
      Array.from(data).forEach((el, i) => {
        if (i <= maxPost) { // total items on home page view
          const srcURL = el.primaryVideo.fields.file.url;
          const srcTitle = el.path; // was title, perhaps use id here
          createPlanes(srcURL, i, srcTitle);
        }
      });
    //  scene.add(groupContainer);
      //  scene.remove(groupContainer);
    }
  //  console.log(scene, 'scene')

  //  console.log(groupArray)
  //  console.log(groupContainer)
  }
  scene.add(groupContainer);

}
