import * as THREE from 'three';

import {
  EffectComposer
} from 'three-full/sources/postprocessing/EffectComposer';
import {
  RenderPass
} from 'three-full/sources/postprocessing/RenderPass';

import {
  ShaderPass
} from 'three-full/sources/postprocessing/ShaderPass';


import {
  TweenMax,
  Power4,
  Elastic
} from 'gsap';

import {
  scene,
  camera
} from './../constants'

import {
  getScrollPercent,
  parallaxPlanes,
  detectScrollDirection,
  scrollProgress
} from './helpers/scroll';

import {
  createAllPanels
} from './helpers/createPlanes';


let renderer;
let container;


function getDistortionShaderDefinition() {
  return {

    uniforms: {
      "tDiffuse": {
        type: "t",
        value: null
      },
      "strength": {
        type: "f",
        value: 0
      },
      "height": {
        type: "f",
        value: 1
      },
      "aspectRatio": {
        type: "f",
        value: 1
      },
      "cylindricalRatio": {
        type: "f",
        value: 1
      }
    },

    vertexShader: [
      "uniform float strength;", // s: 0 = perspective, 1 = stereographic
      "uniform float height;", // h: tan(verticalFOVInRadians / 2)
      "uniform float aspectRatio;", // a: screenWidth / screenHeight
      "uniform float cylindricalRatio;", // c: cylindrical distortion ratio. 1 = spherical

      "varying vec3 vUV;", // output to interpolate over screen
      "varying vec2 vUVDot;", // output to interpolate over screen

      "void main() {",
      "gl_Position = projectionMatrix * (modelViewMatrix * vec4(position, 1.0));",

      "float scaledHeight = strength * height;",
      "float cylAspectRatio = aspectRatio * cylindricalRatio;",
      "float aspectDiagSq = aspectRatio * aspectRatio + 1.0;",
      "float diagSq = scaledHeight * scaledHeight * aspectDiagSq;",
      "vec2 signedUV = (2.0 * uv + vec2(-1.0, -1.0));",

      "float z = 0.5 * sqrt(diagSq + 1.0) + 0.5;",
      "float ny = (z - 1.0) / (cylAspectRatio * cylAspectRatio + 1.0);",

      "vUVDot = sqrt(ny) * vec2(cylAspectRatio, 1.0) * signedUV;",
      "vUV = vec3(0.5, 0.5, 1.0) * z + vec3(-0.5, -0.5, 0.0);",
      "vUV.xy += uv;",
      "}"
    ].join("\n"),

    fragmentShader: [
      "uniform sampler2D tDiffuse;", // sampler of rendered scene?s render target
      "varying vec3 vUV;", // interpolated vertex output data
      "varying vec2 vUVDot;", // interpolated vertex output data

      "void main() {",
      "vec3 uv = dot(vUVDot, vUVDot) * vec3(-0.5, -0.5, -1.0) + vUV;",
      "gl_FragColor = texture2DProj(tDiffuse, uv);",
      "}"
    ].join("\n")

  };
}

let composer;
let effect;

const init = () => {
  container = document.createElement('div');
  document.body.appendChild(container);



  scene.fog = new THREE.FogExp2(0x1a1a1a, 0.25);

  // createAllPanels(scene, data);

  renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true
  });
  renderer.setClearColor(0xFF0000, 0);

  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.appendChild(renderer.domElement);

  var geometry = new THREE.SphereGeometry(5, 32, 32);
  var material = new THREE.MeshBasicMaterial({
    color: 0xffff00
  });
  var sphere = new THREE.Mesh(geometry, material);
  scene.add(sphere);


  // Create effect composer
  composer = new EffectComposer(renderer);
  composer.addPass(new RenderPass(scene, camera));

  // Add distortion effect to effect composer
  effect = new ShaderPass(getDistortionShaderDefinition());
  composer.addPass(effect);
  effect.renderToScreen = true;

  // Setup distortion effect
  var horizontalFOV = 140;
  var strength = 0;
  var cylindricalRatio = 2;
  var height = Math.tan(THREE.Math.degToRad(horizontalFOV) / 2) / camera.aspect;

//  camera.fov = Math.atan(height) * 2 * 180 / 5.5;
//  camera.updateProjectionMatrix();

  effect.uniforms["strength"].value = strength;
  effect.uniforms["height"].value = height;
  effect.uniforms["aspectRatio"].value = camera.aspect;
  effect.uniforms["cylindricalRatio"].value = cylindricalRatio;



  window.addEventListener('scroll', onScroll, false);
  window.addEventListener('resize', onWindowResize, false);

  window.addEventListener('mousedown', mouseDown, false);
  window.addEventListener('mouseup', mouseUp, false);


}

const mouseDown = () => {
  //effect.uniforms[ "strength" ].value +=0.04;
  TweenMax.to(effect.uniforms["strength"], 1.2, {
    value: 0.7,
  //  ease: 'Expo.easeInOut',
  });

}

const mouseUp = () => {
  //effect.uniforms[ "strength" ].value +=0.04;
  TweenMax.to(effect.uniforms["strength"], 6.2, {
    value: 0,
  ease: Elastic.easeOut.config(1, 0.3)
  });

}

const scrollCamera = () => {
  if (!document.body.classList.contains('post')) {
    TweenMax.to(camera.position, 1, {
      y: -(getScrollPercent()) * 0.03, // add 0.01 for each additional tile
      ease: Power4.easeOut
    });
  }
}



const checkScrollSpeed = ((settings) => {
    settings = settings || {};

    var lastPos, newPos, timer, delta,
        delay = settings.delay || 50; // in "ms" (higher means lower fidelity )

    function clear() {
      lastPos = null;
      delta = 0;
    }

    clear();

    return function(){
      newPos = window.scrollY;
      if ( lastPos != null ){ // && newPos < maxScroll
        delta = newPos -  lastPos;
      }
      lastPos = newPos;
      clearTimeout(timer);
      timer = setTimeout(clear, delay);
      return delta;
    };
})();


const onScroll = (e) => {
  scrollCamera();
  //  parallaxPlanes(scene, detectScrollDirection());
  scrollProgress(getScrollPercent());
//  console.log(camera.position.y)
console.log( checkScrollSpeed() );
}


const onWindowResize = () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

const animate = () => {
  requestAnimationFrame(animate);
  render();
  composer.render();

}

const render = () => {
  renderer.render(scene, camera);

}


// export const stuff = () => {
init();
animate();
// }
