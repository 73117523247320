import styled, { css } from 'styled-components';

const ChopSticksSvg = styled.svg`
  width: 90px;
  z-index: 1;
  fill: white;
`;

const ScrollProgressSvg = styled.svg`
  z-index: 1;
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: inline-flex;
  vertical-align: bottom;
  width: 50px;
  height: 50px;

  circle {
    stroke: rgba(#fff, 0.25);
    stroke-width: 5px;
    stroke-dasharray: 0;
    fill: none;
  }

  .meter {
    stroke-width: 5px;
    stroke: #fff;
    fill: none;
    transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transform-origin: center center;
    transform: rotate(-90deg) scaleX(-1);
  }
`;

export { ChopSticksSvg, ScrollProgressSvg };
