import * as THREE from 'three';
import {
  groupArray,
  groupContainer,
  scene,
  camera,
  selectedPlaneGroup
} from '.../../constants'

import {
  TweenMax,
  Power4
} from 'gsap';

const scaleFactor = 2.2;

const loader = new THREE.TextureLoader();
const imageMap = loader.load('https://s3.amazonaws.com/duhaime/blog/tsne-webgl/assets/cat.jpg');

const geoHeight = 1.2;
const geoWidth = 0.8 * 2;
const imgHeight = 1.2;
const imgWidth = 0.8 * 2;

const scaleBackground = (mesh) => {
  const cameraZ = camera.position.z;
  const planeZ = 0;
  const distance = cameraZ - planeZ;
  const aspect = window.innerWidth / window.innerHeight;
  const vFov = camera.fov * Math.PI / 180;
  const planeHeightAtDistance = 2 * Math.tan(vFov / 2) * distance;
  const planeWidthAtDistance = planeHeightAtDistance * aspect;
  return {
    x: planeWidthAtDistance,
    y: planeHeightAtDistance,
    z: 1
  }
};

export default (path) => {
  scene.remove(groupContainer);

  const geometry = new THREE.PlaneBufferGeometry(0.8 * 2, 1.2, .01);
  imageMap.repeat.set(geoHeight / imgHeight, geoWidth / imgWidth);


  const material = new THREE.MeshBasicMaterial({
    //  color: 0xffff00,
    map: imageMap
  });

  const plane = new THREE.Mesh(geometry, material);
  plane.scale.set(scaleBackground(plane).x / 2, -scaleBackground(plane).y, 0.6)
  plane.position.set(0.3, 0, 0)

  selectedPlaneGroup.add(plane);
  scene.add(selectedPlaneGroup);


  /* scene.traverse((child) => {
     if (child instanceof THREE.Mesh) {
       if (child.parent.name === path &&
         child.name === 'primary') {

         TweenMax.to(child.scale, 0.5, {
           x: scaleFactor,
           y: scaleFactor
         })

         TweenMax.to(child.position, 1, {
           x: 0,
           y: 0
         })
       } else {

         TweenMax.to(child.position, 1.5, {
           y: 3
         })
       }
     }
   });  */
}
