import styled from "styled-components";


const SearchContainer = styled.div `
height: 200px;
position: absolute;
margin: auto;
display: block;
width: 100%;
`;

const SearchInput = styled.input `
width: 80%;
max-width: 450px;
background: transparent;
border: 0px solid #ffffff;
border-radius: 50px;
color: white;
font-size: 1.4em;
padding: 9px;
bottom: 0;
position: absolute;
left: 0;
right: 0;
    margin: auto;
    outline: none;
`;

export {
  SearchContainer, SearchInput
};
