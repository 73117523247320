import styled from "styled-components";


const CloseButton = styled.button`
position: absolute;
top: 30px;
right: 30px;
cursor: pointer;
z-index:9
`;


export { CloseButton };
