import React, { Component } from 'react';

import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';


import { Navigation, NavChopSticksContainer, Logo, Menu } from './styles'

import { ChopSticks } from '../elements'

import { navData } from './navData';


class Header extends Component {
  state = {
    isActive: false
  };

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }));
  };

  render() {
    return (
      <Navigation
        aria-label="main navigation">
        <div className="navbar-brand">
          <NavLink className="navbar-item" to="/" activeClassName="is-active">
          <Logo>The Yum Yum Diary</Logo>
          </NavLink>
          <button className="button navbar-burger" onClick={this.toggleNav}></button>
        </div>
        <div
          className={
            this.state.isActive ? "navbar-menu is-active" : "navbar-menu"
          }
        >
<NavChopSticksContainer>
<ChopSticks/>
</NavChopSticksContainer>
          <Menu>

      {
  Object.entries(navData).map((entry, i) => (
    <NavLink
      to={entry[1].to}
      activeClassName={entry[1].activeClassName}
      key={i}>
      {entry[0]}
    </NavLink>
    ))
    }

          </Menu>
        </div>
      </Navigation>
    );
  }
}

export default Header;
