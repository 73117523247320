import React, { Component, Fragment } from 'react';
import {SearchContainer, SearchInput} from './styles';

import {
  InstantSearch,
  HierarchicalMenu,
  RefinementList,
  SortBy,
  Stats,
  Pagination,
  ClearRefinements,
  RangeInput,
  Highlight,
  Panel,
  Configure,
  connectSearchBox,
  connectHits,
  connectStateResults,
} from 'react-instantsearch-dom';
import withURLSync from './URLSync';
import './App.css';

import createGallery from '../../webGL/helpers/createGallery';

import {
  TweenMax,
  Power4,
  Elastic
} from 'gsap';


const maxPostDisplayed = 9;

class Search extends Component {
  constructor(props){
    super(props);
    // reference to the DOM node
    // this.myElement = null;
    // reference to the animation
  //   this.myTween = null;
  }

  componentDidMount(){
    TweenMax.to(document.querySelector('input'), 1, { borderWidth: "10px", ease: Elastic.easeOut.config(1, 0.3), delay: 2 })
    // use the node ref to create the animation
    // this.myTween = TweenMax.to(this.myElement, 1, {x: 100, y: 100});
  }

  render(){
    return  (
      <InstantSearch
        appId="I0OV8LYHOB"
        apiKey="7c1965f4a58fa65035d067edcabf5d67"
        indexName="RecipeData"
        searchState={this.props.searchState}
        createURL={this.props.createURL}
        onSearchStateChange={this.props.onSearchStateChange}
      >
        <Configure hitsPerPage={maxPostDisplayed} />
        <Header />
        <div className="content-wrapper">
          <CustomResults />
        </div>
      </InstantSearch>
    )
  }
}

const Header = () => (
  <SearchContainer className="content-wrapper">
    <ConnectedSearchBox />
  </SearchContainer>
);


const CustomSearchBox = ({ currentRefinement, refine }) => (
  <div className="input-group">
    <SearchInput
      type="text"
      value={currentRefinement}
      onChange={e => refine(e.target.value)}
      autoComplete="off"
      className="form-control"
      id="q"
    />
    <span className="input-group-btn">
      <button className="btn btn-default">
        <i className="fa fa-search" />
      </button>
    </span>
  </div>
);


const CustomHits = ({hits}) => {
  createGallery(hits, maxPostDisplayed); // [TODO] rate limit this

  if (hits.length === 0) {
    return null; // prevents double render
  };

  return (
      <main id="hits">
        <div className="container is-fluid">
          <div className="columns is-multiline">

        {hits.map(hit => (
          <Hit
          item={hit}
          key={hit.objectID}/>
      ))
    }
    </div>
    </div>
      </main>
    );
}

const Hit = ({ item }) => {
  return (
    <article className="hit column is-one-third">
      <div className="product-desc-wrapper">
        <div className="product-name">
          <div className="hit-price">{item.title}</div>

          <Highlight attribute="name" hit={item} />
        </div>


      </div>
    </article>
  );
};

const CustomResults = connectStateResults(({ searchState, searchResult }) => {
  if (searchResult && searchResult.nbHits === 0) {
    return (
      <div className="results-wrapper">
        <div className="no-results">
          No results found matching{' '}
          <span className="query">{searchState.query}</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className="results-wrapper">
        <section id="results-topbar">

          <Stats />
        </section>
        <ConnectedHits />
        <footer>
          <Pagination showLast={true} />
        </footer>
      </div>
    );
  }
});

const ConnectedSearchBox = connectSearchBox(CustomSearchBox);
const ConnectedHits = connectHits(CustomHits);

export default withURLSync(Search);
