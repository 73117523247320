import React, { Component, Fragment } from 'react'
import { Container, Headline, Wrapper, Next } from './styles'

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Markdown from 'react-markdown';
import { getPost } from '../../ducks/posts/actions';

import selectPlane from '../../webGL/helpers/selectPlane';

import {
  TweenMax,
  Power4,
  Elastic
} from 'gsap';

class Developer extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { match, getPost, posts } = this.props;
    const { path } = match.params;

    const isPostLoaded = !!posts.find(post => post.path === path);

    if(!isPostLoaded) {
      getPost(match.params.path);
    }

   const node = this.myRef.current;
   if(node) {
     this.pageTransition(node);
   }

   // add class to body
   document.body.classList.add("post");
  }

  componentDidUpdate() {
    const node = this.myRef.current;
    this.pageTransition(node);
    }


reveal(node) {
  TweenMax.fromTo(node, 2, { width: '100%', float: 'left', ease: Power4.easeIn},
    { width : '50%', ease: Elastic.easeOut.config(1, 0.6)})
}

pageTransition(node) {
  TweenMax.fromTo(node, 0.8, { width: '0%', ease: Power4.easeOut},
    { width : '100%', ease: Power4.easeIn, onComplete: () => { this.reveal(node) }})
}


 componentWillUnmount() {
  console.log('ENDS')
  document.body.classList.remove("post");
}

  render() {
    const { posts, match } = this.props;
    const { path } =  match.params;
    const post = posts.find(post => post.path === path);

    if (!post) {
      return null; // prevents double render
    };

    const { title, content, media } = post;

    const mediaArray = [];
    mediaArray.push(media);
    console.log(mediaArray);

    return (
      <Fragment>
        { selectPlane(path) }
        <Wrapper ref={this.myRef}>
          <Container>
            <Headline>{title}</Headline>
            <Markdown source={content}/>
          </Container>
        </Wrapper>
        <Next>
          Next Recipe
        </Next>
        </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  posts: state.posts
});

const mapDispatchToProps = {
  getPost
};

export default connect(mapStateToProps, mapDispatchToProps)(Developer);
