import * as THREE from 'three';

export const groupArray = []; // array to contain the group of all scene planes
export const groupContainer = new THREE.Group();
export const scene = new THREE.Scene();

export const camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 100000);
camera.position.set(0, 0, 1);

export const selectedPlaneGroup = new THREE.Group();
