import styled from "styled-components";

//  padding: 150px 50px 0px 50px;

const Wrapper = styled.div `
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  top: 0;
  position: relative;
  color: #757575;
`;

const Headline = styled.h1 `
  font-family: "yum-font";
  font-size: 2em;
`;


const Container = styled.div `
   padding: 150px 50px 0px 50px;
`;

const Next = styled.div `

`;


export {
  Container, Headline, Wrapper, Next
};

/*
const Container = styled.div `
  max-width: 780px;
  background: #fdfdfd;
  margin: auto;
  top: 450px;
  position: relative;
  padding: 50px;
  color: #757575;

  img {
    padding: 30px 0 30px 0;
    width: 100%;
  }
`;

const Headline = styled.h1 `
  position: absolute;
  top: 302px;
  left: 51px;
  font-size: 40px;
`;


*/
