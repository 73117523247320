import React, { Component } from 'react';

import { Link, NavLink } from 'react-router-dom';


import { FooterContainer } from './styles'

import { ChopSticks } from '../elements'


class Footer extends Component {
  state = {
    isActive: false
  };

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }));
  };

  render() {
    return (
      <FooterContainer className="columns">
      <div className="column">
        <ChopSticks/>
      </div>
      <div className="column">
        <h3>Navigation</h3>
      </div>
      <div className="column">
        <h3>Stay Conneceted.</h3>
        <p>Facebook</p>
        <p>Instagram</p>
        <p>Twitter</p>
      </div>
      <div className="column">
        <h3>Get the latest news.</h3>
      </div>
    </FooterContainer>
    );
  }
}

export default Footer;
