import {
  TweenMax,
  Power4
} from 'gsap';

const almostZero = 0.000001;
const transitionTime = 1;

export const eraseLines = (mesh, scale, delay) => {
  TweenMax.to(mesh.scale, transitionTime, {
    y: almostZero,
    ease: Power4.easeInOut,
    delay: delay
  });

  if (!mesh.geometry.boundingBox) mesh.geometry.computeBoundingBox();
  const height = mesh.geometry.boundingBox.max.y - mesh.geometry.boundingBox.min.y;

  TweenMax.fromTo(mesh.position, transitionTime, {
    y: mesh.position.y,
  }, {
    y: `+=${height * scale * 1}`,
    ease: Power4.easeInOut,
    delay: delay,
    onComplete: () => {

    }
  });

  TweenMax.fromTo(mesh.material.map.offset, transitionTime, {
    y: mesh.position.y,
  }, {
    y: `+=${height * scale * 1}`,
    ease: Power4.easeInOut,
    delay: delay,
    onComplete: () => {

    }
  });
}

export const expandLines = (mesh, scale, delay) => {
  console.log('EXPAND')
  TweenMax.fromTo(mesh.scale, transitionTime, {
    y: almostZero,
  }, {
    y: -scale,
    ease: Power4.easeInOut,
    delay: delay
  });

  if (!mesh.geometry.boundingBox) mesh.geometry.computeBoundingBox();
  const height = mesh.geometry.boundingBox.max.y - mesh.geometry.boundingBox.min.y;

  TweenMax.fromTo(mesh.position, transitionTime, {
    y: mesh.position.y,
  }, {
    y: `+=${height * scale / 2}`,
    ease: Power4.easeInOut,
    delay: delay
  });


  TweenMax.fromTo(mesh.material.map.offset, transitionTime, {
    y: mesh.position.y,
  }, {
    y: `+=${height * scale / 1.3}`,
    ease: Power4.easeInOut,
    delay: delay
  });
}
