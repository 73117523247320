import styled from "styled-components";

//  padding: 150px 50px 0px 50px;

const Wrapper = styled.div `
  width: 0px;
  min-height: 100vh;
  background: #fdfdfd;
  top: 0;
  position: relative;
  color: #757575;
  float:right;

  img {
    padding: 30px 0 30px 0;
    width: 100%;
  }

  ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
ol li {
  counter-increment: my-awesome-counter;
  display: flex;
  width: 50%;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
ol li::before {
  content: "0" counter(my-awesome-counter);
  font-weight: bold;
  font-size: 3rem;
  margin-right: 0.5rem;
  font-family: "yum-font";
  line-height: 1;
}
`;

const Headline = styled.h1 `
  font-family: "yum-font";
  font-size: 2em;
`;


const Container = styled.div `
   padding: 150px 50px 0px 50px;
`;

const Next = styled.div `

`;


export {
  Container, Headline, Wrapper, Next
};

/*
const Container = styled.div `
  max-width: 780px;
  background: #fdfdfd;
  margin: auto;
  top: 450px;
  position: relative;
  padding: 50px;
  color: #757575;

  img {
    padding: 30px 0 30px 0;
    width: 100%;
  }
`;

const Headline = styled.h1 `
  position: absolute;
  top: 302px;
  left: 51px;
  font-size: 40px;
`;


*/
